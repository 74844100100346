<template>
  <div class="included_style">
    <titles :title="$t('text.t548')" />
    <div class="bigbox">
      <div class="bigbox-t1 bigbox-mb10">{{ $t('text.t556') }}</div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t557') }}</div>
      <div class="bb-inp bb-mb20">
        <van-radio-group v-model="chain" direction="horizontal">
          <!-- <van-radio name="0" icon-size="16px" checked-color="#323232">OMP</van-radio> -->
          <van-radio name="1" icon-size="16px" checked-color="#323232">BSC</van-radio>
          <van-radio name="2" icon-size="16px" checked-color="#323232">ETH</van-radio>
          <van-radio name="3" icon-size="16px" checked-color="#323232">POLYGON</van-radio>
        </van-radio-group>
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t558') }}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="coin" :placeholder="$t('text.t559')"></el-input>
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t560') }}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="address" :placeholder="$t('text.t561')"></el-input>
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t562') }}</div>
      <div class="bb-inp bb-mb20">
        <van-stepper integer :input-width="50" v-model="coin_number" />
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t563') }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :autosize="{ minRows: 3}" maxlength="120" show-word-limit type="textarea" v-model="description" :placeholder="$t('text.t564')"></el-input>
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>Email</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="email" :placeholder="$t('text.t561',{text:'onemoreswap@gmail.com'})"></el-input>
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t566') }}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="url" :placeholder="$t('text.t561',{text:'https://www.tokenpocket.pro'})"></el-input>
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t567') }}</div>
      <div class="bb-inp bb-mb20">
        <el-select v-model="standard" :placeholder="$t('text.t568')">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="bigbox-t2 bigbox-mb10"><span>*</span>{{ $t('text.t569') }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :autosize="{ minRows: 3}" type="textarea" v-model="coin_info" :placeholder="$t('text.t570')"></el-input>
      </div>
      <div class="bigbox-t2 bigbox-mb10">NFT logo </div>
      <div class="bigbox-t3 bigbox-mb10">{{ $t('text.t571') }}</div>
      <div class="bb-mb20">
        <van-uploader :max-count="1" :after-read="afterRead" v-model="fileList" @delete="deleteImg">
          <div class="up_style center-center">
            <span>+</span>
          </div>
        </van-uploader>
      </div>
      <div class="bb-btn">
        <van-button @click="submit" :loading="isloading" block color="#323232" :loading-text="$t('text.t67')">{{ $t('text.t458') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import titles from "@/components/titles/index.vue";
export default {
  components: { titles },
  data(){
    return{
      chain:'0',
      coin:'',
      address:'',
      coin_number:'',
      description:'',
      email:'',
      url:'',
      standard:'',
      coin_info:'',
      image:'',
      options: [
        {
          value: "0",
          label: "NFT-721",
        },
        {
          value: "1",
          label: "NFT-1155",
        },
      ],
      fileList:[],
      isloading:false
    }
  },
  computed: {
    ...mapState(["language"]),
  },
  methods:{
    submit(){
      if (!this.coin) {
        this.$toast(this.$t('text.t559'));
        return;
      }
      if (!this.address) {
        this.$toast(this.$t('text.t561'));
        return;
      }
      if (!this.description) {
        this.$toast(this.$t('text.t564'));
        return;
      }
      if (!this.email) {
        this.$toast(this.$t('text.t572'));
        return;
      }
      if (!this.url) {
        this.$toast(this.$t('text.t573'));
        return;
      }
      if (!this.standard) {
        this.$toast(this.$t('text.t568'));
        return;
      }
      if (!this.coin_info) {
        this.$toast(this.$t('text.t570'));
        return;
      }
      this.isloading = true;
      this.$http.post('/core/employ',{
        contract:this.address,
        chainType:this.chain,
        symbol:this.coin,
        image:this.image,
        number:this.coin_number,
        profile:this.description,
        email:this.email,
        href:this.url,
        nft_type:this.standard,
        information:this.coin_info,
        language:this.language
      }).then(res=>{
        this.$toast(res.msg)
        setTimeout(() => {
          this.$router.back()
          this.isloading = false;
        }, 1000);
      }).catch(err=>{
        this.isloading = false;
      })
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = this.$t("text.t74");
      let ff = new FormData();
      ff.append("file", file.file);
      this.$http
        .upload("/common/upload", ff)
        .then((res) => {
          this.image = res.data.url;
          setTimeout(() => {
            file.status = "done";
            file.message = this.$t("text.t75");
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            file.status = "failed";
            file.message = this.$t("text.t76");
          }, 1000);
        });
    },
    deleteImg() {
      this.image = "";
    },
  }
}
</script>

<style lang="less">
.included_style {
  .van-radio--horizontal{
    margin-bottom: 10px;
  }
  .bigbox {
    width: 321px;
    padding: 41px 0;
    margin: auto;
    .up_style{
      width: 100px;
      height: 100px;
      border-radius: 5px;
      border: 1px dashed #909090;
      span{
        font-size: 24px;
        font-weight: 500;
        color: #909090;
      }
    }
    .box1 {
      text-align: center;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }

    .bigbox-t1 {
      font-size: 16px;
      font-weight: bold;
      color: #323232;
    }

    .ad_bigtitle {
      font-size: 18px;
      color: #515DF7;
    }

    .cs_right {
      width: 30px;
      height: 30px;
      font-size: 20px;
      font-weight: bold;
      color: #515DF7;
      background: #ffffff;
      border: 2px solid #515DF7;
      border-radius: 5px;
      margin-left: 8px;
      box-sizing: border-box;
    }

    .bigbox-t2 {
      font-size: 14px;
      font-weight: bold;
      color: #323232;
      span{
        color: #FF0000;
      }
    }
    .bigbox-t3 {
      font-size: 12px;
      color: #323232;
    }
    .bigbox-mb10 {
      margin-bottom: 10px;
    }

    .bb-mb20 {
      margin-bottom: 20px;
    }

    .add_casting_item_borderbottom {
      border-bottom: 1px solid #f1f1f1;
    }

    .bb-inp {
      width: 100%;

      .el-select {
        display: block;
      }
    }

    .bb-btn {
      width: 300px;
      margin: auto;
      padding-top: 28px;

      .van-button {
        border-radius: 5px;
      }
    }

    .attritem {
      margin-bottom: 15px;

      .left {
        flex: 1;

        .line1 {
          width: 49%;

          .l1title {
            font-size: 12px;
            font-weight: bold;
            color: #323232;
            margin-bottom: 10px;
          }

          .l1inp {
            width: 100%;

            .sufbox {
              height: 100%;
              padding-right: 8px;
            }

            .suf {
              width: 15px;
              height: 15px;
              background: #ffffff;
              border: 1px solid #323232;
              border-radius: 8px;
              font-size: 16px;
              font-weight: bold;
              color: #323232;
            }
          }
        }
      }

      .right {
        width: 30px;
        height: 30px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        background: #ffffff;
        border: 1px solid #323232;
        border-radius: 5px;
        margin-left: 8px;
        box-sizing: border-box;
      }
    }

    .attritem:last-of-type {
      margin-bottom: 0;
    }

    .add-yanzheng {
      flex: auto;
      padding-left: 10px;

      .addbtns {
        background: #323232;
        border-radius: 20px;
        padding: 12px 20px;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .add-yz-left {
      width: 220px;
    }

    .add-status {
      font-size: 12px;
      font-weight: 500;
    }

    .add-status1 {
      color: #4f6ed2;
    }

    .add-status2 {
      color: #d24f4f;
    }
  }
}</style>